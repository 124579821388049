import { SyntheticEvent, useState } from 'react';
import { LoadingStyle, RootStyle } from './CustomImage.style';
import { CustomImageProps } from './CustomImage.type';
import NoImageFound from 'assets/images/NoImageFound.png';
import { GlobalVariables } from 'config/constant';
import { Box } from '@mui/material';

function CustomImage({
  src,
  alt,
  height,
  width,
  clickable,
  sx,
  imgSx,
  onViewImage,
}: Readonly<CustomImageProps>) {
  const [loaded, setLoaded] = useState(false);

  const onImageError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.src = NoImageFound;
    event.currentTarget.alt = GlobalVariables.EmptyString;
  };

  return (
    <Box sx={sx}>
      <RootStyle
        src={src}
        alt={alt}
        height={loaded ? height : '0px'}
        width={loaded ? width : '0px'}
        loading="lazy"
        onError={onImageError}
        onLoad={() => setLoaded(true)}
        onClick={onViewImage}
        clickable={clickable ? 1 : 0}
        sx={imgSx}
      />
      {loaded ? null : <LoadingStyle sx={sx} width={width} height={height} variant="rectangular" />}
    </Box>
  );
}

export default CustomImage;
