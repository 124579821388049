import { createSlice } from '@reduxjs/toolkit';
import { AppPaletteMode } from 'config/enums/app.enum';
import { RootState } from 'redux/store';
import { ESnackBarSeverity, ISnackBar } from 'types/interfaces/SnachBar';
import NavItem from 'types/interfaces/NavItem';

interface AppState {
  mode: AppPaletteMode;
  navBarConfig: {
    active?: boolean;
    title?: string;
    subtitle?: string;
    searchText: string;
    searchPlaceholder?: string;
    searchDisabled?: boolean;
  };
  routeConfig: NavItem[];
  isLoadingRouteConfig?: boolean;
  isErrorRouteConfig?: boolean;
  snackBarConfig: ISnackBar;
}
const initialState: AppState = {
  mode: AppPaletteMode.Light,
  navBarConfig: {
    searchText: '',
  },
  snackBarConfig: {
    open: false,
    message: '',
    title: '',
    severity: ESnackBarSeverity.INFO,
  },
  routeConfig: [],
  isLoadingRouteConfig: false,
  isErrorRouteConfig: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    switchMode: (state) => {
      // NOTE: Replace this with a switch statement if you have more than 2 modes
      state.mode = state.mode === AppPaletteMode.Light ? AppPaletteMode.Dark : AppPaletteMode.Light;
    },
    setSearchText: (state, action) => {
      state.navBarConfig.searchText = action.payload;
    },
    setNavBarConfig: (state, action) => {
      state.navBarConfig = action.payload;
      state.navBarConfig.searchText = '';
    },
    resetNavBarConfig: (state) => {
      state.navBarConfig = initialState.navBarConfig;
    },

    setSnackBarConfig: (state, action) => {
      state.snackBarConfig = action.payload;
    },
    resetSnackBarConfig: (state) => {
      state.snackBarConfig.open = false;
      state.snackBarConfig.message = '';
      state.snackBarConfig.title = '';
    },
    setRouteConfig: (state, action) => {
      state.routeConfig = action.payload;
    },
    setIsLoadingRouteConfig: (state, action) => {
      state.isLoadingRouteConfig = action.payload;
    },
    setIsErrorRouteConfig: (state, action) => {
      state.isErrorRouteConfig = action.payload;
    },
    resetRouteConfig: (state) => {
      state.routeConfig = initialState.routeConfig;
    },
  },
});

export const {
  switchMode,
  setSearchText,
  setNavBarConfig,
  resetNavBarConfig,
  setSnackBarConfig,
  resetSnackBarConfig,
  setRouteConfig,
  setIsLoadingRouteConfig,
  setIsErrorRouteConfig,
} = appSlice.actions;
export default appSlice.reducer;
export const GetSnackConfig = (state: RootState) => state.appReducer.snackBarConfig;
export const GetIsLoadingRouteConfig = (state: RootState) => state.appReducer.isLoadingRouteConfig;
export const GetSearchText = (state: RootState) => state.appReducer.navBarConfig.searchText;
export const GetRouteConfigStore = (state: RootState) => state.appReducer.routeConfig;
export const GetIsErrorRouteConfig = (state: RootState) => state.appReducer.isErrorRouteConfig;
