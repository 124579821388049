import { Button, Stack } from '@mui/material';
import { TypographyFallbackError } from 'layouts/commonLayout.style';
import { Trans } from 'react-i18next';
import { translate } from 'locales/i18n';
import { ICustomFallbackErrorProps } from 'components/CustomFallbackError/CustomFallbackError.type';

export function CustomFallbackError({
  fallbackFn,
  customMessage,
}: Readonly<ICustomFallbackErrorProps>) {
  return (
    <Stack spacing={5} marginY={5}>
      <TypographyFallbackError variant="h5">
        <Trans
          i18nKey={customMessage ?? 'common.error_fallback'}
          components={{ newLine: <pre /> }}
        />
      </TypographyFallbackError>
      <Button onClick={fallbackFn}>{translate('common.reload')}</Button>
    </Stack>
  );
}
