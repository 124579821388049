import { ErrorOperationEnum } from 'config/enums/common.enums';

export const errorApi = {
  CLIENT_ALREADY_EXISTS: 'Ce client existe déjà',
  USER_ALREADY_EXISTS: `Utilisateur avec l'email {{login}} existe déjà`,
  USER_ALREADY_EXISTS_WITH_PHONE: `Utilisateur avec le numéro de téléphone {{login}} existe déjà`,
  INSUFFICIENT_ZONE_STOCK: "La zone sélectionnée n'a pas assez de stock pour cette offre",
  UNRESPECTED_MAX_ORDER_QTY: 'La quantité maximale de commande pour {{article}} est {{qty}}',
  UNRESPECTED_MIN_ORDER_PRICE: 'Le prix de commande minimum {{price}} pour le client {{client}}',
  CLIENT_SCOPES_UNIQUE: 'Les scopes produits doivent etre uniques',
  PRICE_NOT_FOUND: 'Aucun prix trouvé  pour cet article',
  INVALID_DEAL_ITEMS: "L'affaire doit comporter des éléments de base et des avantages.",
  INSUFFICIENT_STOCK: "L'article {{article}} n'a pas assez de stock",
  ZONE_ALREADY_EXISTS: 'Cette zone existe déjà ',
  SECTOR_ALREADY_EXISTS: 'Ce secteur existe déjà',
  CLIENT_CLASS_ALREADY_EXISTS: 'Cette classe de client existe déjà',
  CLIENT_CATEGORY_ALREADY_EXISTS: 'Cette catégorie de client existe déjà',
  WAREHOUSE_ALREADY_EXISTS: 'Cet dépot existe déjà',
  CATALOG_ALREADY_EXISTS: 'Ce catalogue existe déjà ',
  ENTITY_RELATED_TO_ENTITY_CONSTRAINT:
    'Impossible de {{operation}} cet {{entity}} car il est assigné à ce(s) commande(s) {{orders}} , veuillez cloturer/annuler ces commandes avant de supprimer cet élément',
  ENTITY_RELATED_TO_ENTITY_CONSTRAINT_SALES_LINE:
    'Ce compte est rattaché à des configurations clients pour cette ligne de vente {{salesLine}}',
  FOREIGN_KEY_CONSTRAINT:
    'Impossible de supprimer cet élément car il est assigné à d’autres  parties , Merci de vérifier votre configuration.',
  FOREIGN_KEY_CONSTRAINT_UPDATE:
    'Impossible de modifier cet élément car il est assigné à d’autres  parties , Merci de vérifier votre configuration.',
  FOREIGN_KEY_CONSTRAINT_DEACTIVATE:
    'Impossible de désactiver cet élément car il est assigné à d’autres  parties , Merci de vérifier votre configuration.',
  INCOMPLETE_ENTITY: 'Entité incomplète',
  ORDER_CAN_NOT_BE_UPDATED:
    'La commande ne peut pas être mise à jour car elle est déjà {{status}} ',
  CHILDLESS_CATALOG: 'Aucune sous-catalogue associée à ce catalogue',
  BLOCKED_CLIENT: 'Ce client est bloqué',
  OVERLAPPING_DISCOUNTS_FOUND: 'Produit {{nameProduct}} rattaché à la remise {{nameDiscount}}',
  OVERLAPPING_DISCOUNTS_FOUND_WITHOUT_NAME: 'Produit rattaché à la remise {{nameDiscount}}',
  OVERLAPPING_DEALS_FOUND: "Produit {{nameProduct}} rattaché à l'action commerciale {{nameDeal}}",
  OVERLAPPING_DEALS_FOUND_WITHOUT_NAME: 'Produit rattaché à l action commerciale {{nameDeal}}',
  OVERLAPPING_PROMO_CODES_FOUND:
    'Cette code promo avait un chevauchement avec le code promo {{code}}',
  PRODUCT_DEAL_NOT_EMPTY: 'Les produits ne peuvent pas être vides',
  SALES_LINE_ALREADY_EXISTS: 'Cette ligne de vente existe déjà',
  ARTICLE_DOES_NOT_EXIST: "L'article {{article}} n'existe pas",
  ACCESS_PROFILE_ALREADY_EXISTS: "Ce profil d'accès existe déjà",
  MEDIA_ERROR: 'Erreur lors de la mise à jour du média',
  FOREIGN_KEY_CONSTRAINT_PRICE: 'Impossible de supprimer ce prix car il est assigné à une remise',
  PROMO_CODE_EXISTS: `Le code promo {{code}} existe deja`,
  PROMO_CODE_EXISTS_ARCHIVED: `Le code promo {{code}} existe deja et est archivé`,
  ORDERS_SYNC_FAILS: 'Un problème avec le système Sage a empêché la synchronisation des commandes.',
  MISSING_CLIENT_CATEGORY_SAGE_ID: " Les clients de ces commandes n'ont de catégorie client Sage",
  MISSING_CLIENT_CODE: "Les clients de ces commandes n'ont pas de code client",
  MISSING_AGENT_CODE: "Ces commandes n'ont pas de code agent",
  MISSING_SALES_LINE: "Ces commandes n'ont pas de ligne de vente",
  ALREADY_SYNCHED: 'Ces commandes ont déjà été synchronisées',
  CART_NOT_FOUND: "Une erreur est survenue . <newLine/> <newLine/>  Le panier n'a pas été trouvé",
  DISABLED_USER: 'Votre compte est désactivé, veuillez contacter votre responsable',
  ORDER_DOES_NOT_EXIST: "La commande avec la référence {{orderRef}} n'existe pas",
  ENTITY_ARCHIVED: 'Cette entité avec {{attribute}} {{value}} est archivée',
  UNRESPECTED_MIN_ORDER_QTY:
    "La quantité minimale {{qty}} pour l'article {{article}} n'est pas respectée",
  operation: {
    [ErrorOperationEnum.UPDATE]: 'mettre à jour',
    [ErrorOperationEnum.DELETE]: 'supprimer',
  },
};
