export const GovernoratesStartCode = 'TN';
export enum BooleanToDom {
  TRUE = 'true',
  FALSE = 'false',
}
export enum ClientStatusEnum {
  Active = 'active',
  Pending = 'pending',
  Blocked = 'blocked',
}
export enum PaymentMethodEnum {
  Cash = 'cash',
  Check = 'check',
  Mixed = 'mixed',
}
export enum ErrorOperationEnum {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  DEACTIVATE = 'DEACTIVATE',
}
