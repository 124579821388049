import { createApi } from '@reduxjs/toolkit/query/react';
import { ENDPOINTS } from 'config/constant/endpoints.config';
import Paginator from 'types/interfaces/Paginator';
import { injectParams } from 'utils/helpers/api-url';
import {
  injectDealStatesFilter,
  injectExcludedDiscountsFilter,
  injectExpand,
  injectFilterArticleByWarehouses,
  injectFilterClientCategoryId,
  injectFilterClientClassArray,
  injectFilterClientId,
  injectFilterExcludeDiscountType,
  injectFilterHasDiscountArticle,
  injectFilterHasSupportArticle,
  injectFilterIds,
  injectFilterProductBrand,
  injectFilterProductCatalog,
  injectFilterProductPriceType,
  injectFilterProductType,
  injectFilterSectorId,
  injectFilterStatus,
  injectFilterStockClients,
  injectFilterZoneId,
  injectShouldDisableFilterStock,
  paginatorToApiPagination,
} from 'utils/services/api.service';
import { baseQueryConfig } from '../BaseQueryConfig';
import {
  transformArticlePricesResponse,
  transformArticlesResponse,
  transformGetArticleByIdResponse,
  transformGetArticlePcbResponse,
  transformGetStockArticle,
  transformPackingUnitsResponse,
} from './articlesApi.transform';
import {
  ArticlesApiResponse,
  ArticlesResponse,
  IArticleByIdResponse,
  IArticleByIdResponseApi,
  IArticlePrice,
  IArticlePriceApi,
  IPackingUnitResponse,
  IPackingUnitsApiResponse,
  IPcbResponseApi,
  IStockArticleResponse,
  IStockArticleResponseApi,
} from 'types/models/Article/article';
import {
  DealStatesEnum,
  ExpandEnum,
  Nullable,
  ProductPriceTypeEnum,
  ProductTypeEnum,
} from 'types/interfaces/sharedType.type';
import { DiscountTypeEnum } from 'types/enum/discount-type.enum';
import { BooleanString } from 'types/interfaces/BooleanString';

export const articlesApi = createApi({
  reducerPath: 'articlesApi',
  baseQuery: baseQueryConfig,
  tagTypes: ['Articles'],
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: (params: {
        status: boolean | null;
        paginator?: Paginator;
        productType?: ProductTypeEnum | null;
        priceType?: ProductPriceTypeEnum | null;
        catalogId?: number | null;
        brandId?: number | null;
        clientId?: number | null;
        hasSupport?: Nullable<BooleanString>;
        hasDiscount?: Nullable<BooleanString>;
        clientCategoryId?: number | null;
        expand?: ExpandEnum[] | null;
        dealState?: DealStatesEnum[] | null;
        excludedDiscounts?: number[] | null;
        stockClientsIds?: Nullable<number[]>;
        excludeDiscountType?: Nullable<DiscountTypeEnum>;
        warehouseIds?: Nullable<number[]>;
        shouldDisableFilterStock?: Nullable<BooleanString>;
        sectorIds?: Nullable<number[]>;
        clientClassIds?: Nullable<number[]>;
        zoneIds?: Nullable<number[]>;
        ids?: Nullable<number[]>;
      }) =>
        injectParams(ENDPOINTS.ARTICLES.GET, {
          ...paginatorToApiPagination(params.paginator),
          ...injectFilterProductType(params.productType),
          ...injectFilterProductPriceType(params.priceType),
          ...injectFilterProductCatalog(params.catalogId),
          ...injectFilterProductBrand(params.brandId),
          ...injectFilterStatus(params.status),
          ...injectFilterHasSupportArticle(params.hasSupport),
          ...injectFilterClientId(params.clientId),
          ...injectFilterHasDiscountArticle(params.hasDiscount),
          ...injectFilterClientCategoryId(params.clientCategoryId),
          ...injectExpand(params.expand),
          ...injectDealStatesFilter(params.dealState),
          ...injectExcludedDiscountsFilter(params.excludedDiscounts),
          ...injectFilterStockClients(params.stockClientsIds),
          ...injectFilterExcludeDiscountType(params.excludeDiscountType),
          ...injectFilterArticleByWarehouses(params.warehouseIds),
          ...injectShouldDisableFilterStock(params.shouldDisableFilterStock),
          ...injectFilterSectorId(params.sectorIds),
          ...injectFilterZoneId(params.zoneIds),
          ...injectFilterClientClassArray(params.clientClassIds),
          ...injectFilterIds(params.ids),
        }),
      transformResponse: (response: ArticlesApiResponse): ArticlesResponse => {
        return transformArticlesResponse(response);
      },
      providesTags: ['Articles'],
    }),
    getArticleById: builder.query({
      query: (id: string) => ENDPOINTS.ARTICLES.GET_BY_ID(id),
      transformResponse: (response: IArticleByIdResponseApi): IArticleByIdResponse => {
        return transformGetArticleByIdResponse(response);
      },
      providesTags: ['Articles'],
    }),
    getPackingUnits: builder.query({
      query: () => ENDPOINTS.ARTICLES.GET_PACKING_UNITS,
      transformResponse: (response: IPackingUnitsApiResponse[]): IPackingUnitResponse[] => {
        return transformPackingUnitsResponse(response);
      },
      providesTags: ['Articles'],
    }),
    postArticle: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS.ARTICLES.POST,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    updateArticle: builder.mutation({
      query: ({ body, id }) => ({
        url: ENDPOINTS.ARTICLES.PATCH(id),
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    postMediaArticle: builder.mutation({
      query: ({ body, id }) => ({
        url: ENDPOINTS.ARTICLES.POST_MEDIA(id),
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    deleteMediaArticle: builder.mutation({
      query: ({ body, id }) => ({
        url: ENDPOINTS.ARTICLES.DELETE_MEDIA(id),
        method: 'POST',
        body,
      }),
    }),
    updateMediaArticle: builder.mutation({
      query: ({ body, idArticle, idMedia }) => ({
        url: ENDPOINTS.ARTICLES.PUT_MEDIA(idArticle, idMedia),
        method: 'PATCH',
        body,
      }),
    }),
    getArticlePcb: builder.query({
      query: (id: number) => ENDPOINTS.ARTICLES.GET_ARTICLE_PCB(id),
      transformResponse: (response: IPcbResponseApi[]) => {
        return transformGetArticlePcbResponse(response);
      },
      providesTags: ['Articles'],
    }),
    putArticlePcb: builder.mutation({
      query: ({ body, id }) => ({
        url: ENDPOINTS.ARTICLES.PUT_ARTICLE_PCB(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    getStockArticle: builder.query({
      query: (params: { paginator?: Paginator; articleId: number }) =>
        injectParams(ENDPOINTS.ARTICLES.GET_STOCK(params.articleId), {
          ...paginatorToApiPagination(params.paginator),
        }),
      transformResponse: (response: IStockArticleResponseApi): IStockArticleResponse => {
        return transformGetStockArticle(response);
      },
    }),
    getArticleFromSage: builder.query({
      query: (id: string) => ENDPOINTS.ARTICLES.GET_ARTICLE_FROM_SAGE(id),

      transformResponse: (response: IArticleByIdResponseApi): IArticleByIdResponse =>
        transformGetArticleByIdResponse(response),
    }),
    getArticlePrices: builder.query({
      query: (id: number) => ENDPOINTS.ARTICLES.Get_Article_Prices(id),
      transformResponse: (response: IArticlePriceApi[]): IArticlePrice[] => {
        return transformArticlePricesResponse(response);
      },
      providesTags: ['Articles'],
    }),
    updateArticlePrices: builder.mutation({
      query: ({ body, id }) => ({
        url: ENDPOINTS.ARTICLES.PUT_Article_Prices(id),
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Articles'],
    }),
    deleteArticle: builder.mutation({
      query: ({ id }) => ({
        url: ENDPOINTS.ARTICLES.Delete_Article(id),
        method: 'DELETE',
      }),
      invalidatesTags: ['Articles'],
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetArticleByIdQuery,
  useGetPackingUnitsQuery,
  useUpdateArticlePricesMutation,
  useUpdateArticleMutation,
  usePutArticlePcbMutation,
  usePostMediaArticleMutation,
  useUpdateMediaArticleMutation,
  usePostArticleMutation,
  useGetArticlePcbQuery,
  useDeleteMediaArticleMutation,
  useGetStockArticleQuery,
  useGetArticlePricesQuery,
  useDeleteArticleMutation,
  useGetArticleFromSageQuery,
  useLazyGetArticlesQuery,
} = articlesApi;
