import { InputObject, InputSize, InputTypes, OptionType } from 'types/interfaces/Input.type';
import { translate } from 'locales/i18n';
import { isSuperiorOrSameDate, isValidDate } from 'utils/helpers/date.helpers';
import { ValidationMessages } from 'utils/validators/input.validators';
import { AppliedToPromoCodeEnum } from 'types/interfaces/sharedType.type';

export enum AddCodePromoFormEnum {
  CodePromo = 'codePromo',
  LimiteUseNumber = 'limiteUseNumber',
  BeginDate = 'beginDate',
  EndDate = 'endDate',
  AppliedTo = 'appliedTo',
  Zone = 'zone',
  Sector = 'sector',
  CategoryClient = 'categoryClient',
  ClientClass = 'clientClass',
  Client = 'client',
  Discount = 'discount',
  DiscountType = 'discountType',
  DiscountValue = 'discountValue',
}

export const CODE_PROMO_CONFIG_LEFT: InputObject[] = [
  {
    label: 'inputs.promoCode.promo_code',
    inputType: InputTypes.TEXT,
    fieldName: AddCodePromoFormEnum.CodePromo,
    config: ValidationMessages,
    defaultValue: '',
    placeholder: 'inputs.placeholder.enter_promo_code',
    size: InputSize.MEDIUM,
  },
  {
    label: 'inputs.promoCode.limit',
    inputType: InputTypes.NUMBER,
    fieldName: AddCodePromoFormEnum.LimiteUseNumber,
    config: ValidationMessages,
    defaultValue: '',
    placeholder: '00',
    size: InputSize.MEDIUM,
  },
];
export const CODE_PROMO_CONFIG_RIGHT = (startDate: string | undefined): InputObject[] => [
  {
    label: 'inputs.promoCode.begin_date',
    inputType: InputTypes.DATE,
    fieldName: AddCodePromoFormEnum.BeginDate,
    size: InputSize.MEDIUM,
    valueOptions: {
      disablePast: true,
    },
    config: {
      valueAsDate: true,
      required: 'common.required',
      validate: {
        value: (value) =>
          startDate // updateMode
            ? isSuperiorOrSameDate(value, startDate) || 'inputs.promoCode.begin_date_error_update'
            : isValidDate(value, undefined, true) || 'inputs.promoCode.begin_date_error',
      },
    },
  },
  {
    label: 'inputs.promoCode.end_date',
    inputType: InputTypes.DATE,
    fieldName: AddCodePromoFormEnum.EndDate,
    size: InputSize.MEDIUM,
    valueOptions: {
      disablePast: true,
    },
    config: {
      valueAsDate: true,
      required: 'common.required',
      validate: {
        value: (value, formValues) =>
          isValidDate(value, formValues?.[AddCodePromoFormEnum.BeginDate], true) ||
          'inputs.promoCode.end_date_error_code_promo',
      },
    },
  },
];
export const TargetTabConfig: InputObject[] = [
  {
    inputType: InputTypes.RADIO,
    fieldName: AddCodePromoFormEnum.AppliedTo,
    label: 'inputs.promoCode.applied_to',
    radioSpacing: 16,
    isDirectionRadioRow: true,
    defaultValue: 0,
    options: [
      {
        value: 0,
        label: 'inputs.promoCode.all_basket',
      },
      {
        value: 1,
        label: 'inputs.promoCode.products',
      },
    ],
  },
];
export const transformAppliedToFromApiToRadio = (appliedTo: string) =>
  appliedTo === AppliedToPromoCodeEnum.Cart ? 0 : 1;
export const ALL_OPTION: OptionType<number> = { label: translate('common.all'), value: 0 };
export const PROMO_CODE_TABS = [
  {
    value: 0,
    label: 'tabs.promo_code',
  },
  {
    value: 1,
    label: 'tabs.promo_cible',
  },
  {
    value: 2,
    label: 'tabs.promo_manager_price',
  },
];
