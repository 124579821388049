export const page = {
  go_mui_components_dashboard: 'Go to MUI Components Dashboard',
  card: 'Cards',
  typography: 'Typography',
  color: 'Colors',
  button: 'Buttons',
  shadow: 'Shadows',
  translations: 'Traductions',
  statistics: 'Statistiques',
  add_account: 'Ajouter un compte',
  add_product: 'Ajouter un produit',
  edit_product: 'Modifier un produit',
  add_warehouse: 'Ajouter un Dépôt',
  edit_warehouse: 'Modifier un Dépôt',
  add_ad: 'Ajouter une publicité',
  update_ad: 'Modifier une publicité',
  dashboard: 'Dashboard',
  accounts: 'Comptes',
  add_promo_code: 'Ajouter un code promo',
  edit_promo_code: 'Modifier un code promo',
  add_deals: 'Ajouter un deal',
  edit_deals: 'Modifier un deal',
  teams: 'Equipes',
  users: 'Utilisateurs',
  clients: 'Clients',

  commands: 'Commandes',
  products: 'Produits',
  categories: 'Catégories',
  warehouse: 'Dépôts',
  regions: 'Regions',
  schedule: 'Gestion des cadenciers',

  ad: 'Espace publicitaire',
  notification: 'Notifications',
  deals: 'Gestion des offres',
  promo: 'Code promo',
  categories_client: 'Catégories Clients',
  classe_client: 'Classes Clients',
  add_client_class: 'Ajouter Classe Client',
  edit_client_class: 'Modifier Classe Client',
  classe_category: 'Catégorie Client',
  add_client_category: 'Ajouter Catégorie Client',
  edit_client_category: 'Modifier Catégorie Client',
  area: 'Gestion des zones et secteurs',
  profile_access: 'Accès Profils',
  add_user: 'Ajouter Admin',
  edit_user: 'Modifier Admin',
  add_notification: 'Ajouter Notification',
  edit_notification: 'Modifier Notification',
  order: 'Commandes',
  add_order: 'Ajouter une commande',
  edit_order: 'Modifier une commande',
  Categories: {
    no_data_yet: 'Aucune catégorie pour le moment',
    search: 'Rechercher une catégorie',
    add: 'Ajouter une nouvelle catégorie',
    edit: 'Modifier une catégorie',
    list: 'Liste des catégories',
    designation: 'Désignation de la catégorie des produits',
    principal: 'Catégorie principale',
    sub_category: 'Sous catégorie',
    type: 'Type de catégorie',
    image_big_format: 'Image de la catégorie (format 16:9)',
    image_small_format: 'Image de la catégorie (format 1:1)',
    delete_warning_message: 'Voulez-vous vraiment supprimer cette catégorie ?',
  },
  Products: {
    list: 'Liste des Produits',
    delete_warning_message: 'Voulez-vous vraiment supprimer ce produit ?',
    no_data_yet: 'Aucun produit pour le moment',
    add: 'Ajouter un produit',
  },
  Ads: {
    list: 'Espace Pubs',
  },
  ProfileAccess: {
    list: 'Liste des Profils',
  },
  Warehouse: {
    list: 'Liste des Dépôts',
  },
  ClientClasses: { list: 'Liste des classes clients' },
  ClientCategories: { list: 'Liste des catégories des clients' },
  Team: { list: 'Liste des Admins' },
  Zone: {
    search: 'Rechercher une zone',
    add: 'Ajouter une zone',
    edit: 'Modifier une zone',
    list: 'Liste des zones et secteurs',
    delete_warning_message: 'Voulez-vous vraiment supprimer cette zone ?',
    governorates: 'Gouvernorats',
    delegations: 'Délégations',
    designation: 'Désignation de la zone',
    search_gouvernorat: 'Rechercher une gouvernorat',
    zones: 'Zones',
    add_sector: 'Ajouter un secteur',
    nameFr: 'Nom en français',
    nameAr: 'Nom en arabe',
  },
  Sector: {
    delete_warning_message: 'Voulez-vous vraiment supprimer ce secteur ?',
    add: 'Ajouter un nouveau secteur',
    edit: 'Modifier un secteur',
    designation: 'Désignation du secteur',
    choose_zone: 'Choisir une zone',
    attraction_site: "Le pouvoir d'attraction du site",
    profil: 'Profil du secteur de chalandise',
  },
  Client: {
    info: 'Informations du client',
    add: 'Ajouter un nouveau client',
    edit: 'Modifier un client',
    list: 'Liste des clients',
    config: 'Configuration du client',
    mat_fiscale: 'Patente',
    raison_sociale: 'Raison sociale',
    sales_line_not_exist:
      "La catégorie commerciale {{catalog}} n'appartient pas à aucune ligne de vente dans le depots {{warehouse}}",
    Selector: {
      client_class: 'Classe client',
      payment_method: 'Méthode de paiement',
      risque_client: 'Risque client',
      status_client: 'Statut client',
      category_client: 'Catégorie client',
      zone: 'Zone',
      sector: 'Secteur',
      category_product: 'Catégorie produit',
      warehouse: 'Dépôt',
      validator: 'Validateur de commande',
    },
  },
  PromoCode: {
    no_selected_product: 'Veuillez sélectionner un / plusieurs produits',
    no_selected_client_category: 'Veuillez sélectionner une / plusieurs catégories de clients',
    List: {
      search: 'Rechercher un code promo',
      add: 'Ajouter un code promo',
      list: 'Liste des codes promo',
      code: 'Code',
      begin_date: 'Date de début',
      end_date: 'Date de fin',
      nameFr: 'nameFr',
      nameAr: 'nameAr',
      status: 'Statut',
      delete_warning_message: 'Voulez-vous vraiment supprimer ce code promo ?',
    },
  },
  Deals: {
    standard_type: 'Bundle',
    package_type: 'Bundle conditionné',
    discount_type: 'Remise',
    delete_warning_message: 'Voulez-vous vraiment supprimer ce deal ?',
    discount_config_mismatch:
      "La superposition entre le positionnement et l'action commerciale a généré des marges négatives. Veuillez corriger les produits en rouge dans le résumé.",
    List: {
      search: 'Rechercher un deal',
      add: 'Ajouter un deal',
      list: 'Liste des deals',
      designation: 'Désignation',
      start_date: 'Date de début',
      end_date: 'Date de fin',
      promo_type: 'Type de promotion',
      delete_warning_message_discount: 'Voulez-vous vraiment supprimer cette remise ?',
      delete_warning_message_positioning: 'Voulez-vous vraiment supprimer ce positionnement ?',
      search_discount: 'Rechercher une remise',
      search_positioning: 'Rechercher un positionnement',
      list_discount: 'Liste des remises',
      list_positioning: 'Liste des positionnements',
      add_discount: 'Ajouter une remise',
      add_positioning: 'Ajouter un positionnement',
      edit_discount: 'Modifier une remise',
      edit_positioning: 'Modifier un positionnement',
      warehouses: 'Dépôts',
      catalogs: 'Catégories',
    },
  },
  Ordre: {
    synched: 'Synchronisé',
    notSynched: 'Non synchronisé',
    cant_load_client: 'Impossible de récupérer le client sélectionné',
    ordre_details: 'Détails de la commande',
    product_to_ordre: 'Produits à commander',
    basket_details: 'Détails du panier',
    history: 'Historique du commande',
    cart_history: 'Historique du panier',
    view_cart_history: "Voir l'historique du panier",
    order_confirmed_info: 'La commande est déjà confirmée',
    confirm_cancel_order: 'Voulez-vous vraiment annuler cette commande ?',
    alt: {
      product_image: 'Image du produit',
    },
    total_ht: 'Total TTC',
    promo_code_applied: 'Code promo appliqué',
    total_payed_ht: 'Total à payer TTC',
    date_delivery: 'Date prévue de livraison',
    payment_method: 'Méthode de paiement',
    client_note: 'Note du client',
    add: 'Ajouter une commande',
    Listing: {
      list: 'Liste des commandes',
      search: 'Rechercher une commande',
      reference: 'Référence',
      client: 'Client',
      region: 'Région',
      status: 'Statut',
      phone: 'Téléphone',
      date: 'Date',
      hour: 'Heure',
      responsable: 'Responsable',
      validation: 'Validation',
      zone: 'Zone',
      synchro_sage: 'Synchroniser avec sage',
      invoice_order: 'Facturer la/les commande(s)',
      error_empty_ordersIds: 'Veuillez sélectionner au moins une commande',
      error_empty_orders_valide:
        'Veuillez sélectionner au moins une commande satisfaisant les contraintes',
      error_access_sync: "Vous n'avez pas l'autorisation de synchroniser cette/ces commande(s).",
      error_invoice:
        "Vous ne pouvez pas facturer cette/ces commande(s) car elle(s) n'est/sont pas en cours de préparation.",
      synchro_warning_message: 'Voulez-vous vraiment synchroniser ces commandes ?',
      invoice_warning_message: 'Voulez-vous vraiment facturer ces commandes ?',
      under_cutoff: 'Hors cadenciers',
      in_cutoff: 'Dans cadenciers',
      cant_select_order: "Vous n'avez pas l'autorisation de sélectionner cette commande",
      created_by: 'Créateur',
    },

    Status: {
      new: 'Nouveau',
      validated: 'Confirmé',
      canceled: 'Annulé',
      delivered: 'Livré',
      shipped: 'Expédié',
      updated: 'Mis à jour',
      preparing: 'En préparation',
      invoiced: 'Facturé',
      created: 'Créé',
    },
    Logs: {
      new: 'Nouvelle commande',
      updated: 'Modification commande',
      validated: 'Validation de la commande',
      prepared: 'Préparation de la commande',
      invoiced: 'Facturation de la commande',
      canceled: 'Annulation de la commande',
    },
    CartLogs: {
      cleared: 'Panier vidé',
      created: 'Panier créé',
      updated: 'Panier mis à jour',
      saved: 'Element sauvegardé sous le nom de <bold>{{name}}</bold>',
      no_data_yet: 'Aucun historique pour le moment',
    },
    Diffs: {
      added: 'Element ajouté',
      removed: 'Element supprimé',
      updated: 'Element modifié',
    },
    packingDiffs: {
      added: 'Colisage ajouté',
      removed: 'Colisage supprimé',
      updated: 'Colisage modifié',
    },
  },

  ManageLP: {
    nameFr: 'Nom en français',
    nameAr: 'Nom en arabe',
    title: "Gestion du page d'accueil pour la zone {{zoneName}}",
    pageTitle: "Gestion du page d'accueil",
    no_selected_product: "Aucun produit n'est sélectionné",
    section: 'Rubrique {{number}}',
    error_validation_product: 'Veuillez sélectionner quatre produits par rubrique',
    confirm_reset_product: 'Les produits selectionnés vont être supprimés, voulez-vous continuer ?',
    redirectionType: {
      deal: 'Deal',
      eshop: 'Eshop',
      title: 'Choisir le type de redirection',
    },
  },
  categoriesLogistic: {
    List: {
      search: 'Rechercher une catégorie logistique',
      add: 'Ajouter une catégorie logistique',
      list: 'Liste des catégories logistiques',
      status: 'Statut',
      delete_warning_message: 'Voulez-vous vraiment supprimer cette catégorie ?',
    },
    add_category: 'Ajouter une catégorie logistique',
    edit_category: 'Modifier une catégorie logistique',
  },
};
